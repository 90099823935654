.playlists {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 20px 50px;
}

h1 {
    font-family: Proto;
    color: #FFFFFF;
}

@media screen and (max-width: 768px) {
    .playlists {
        padding: 10px 20px;
    }
}