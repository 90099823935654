a {
    text-decoration: none;
}

.button {
    background-color: #00aaaa;
    color: #111111;
    font-family: Proto;
    font-size: 20px;
    height: 50px;
    width: auto;
    border-radius: 10px;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button_img {
    height: 30px;
    width: auto;
    margin-right: 10px;
}

.button2 {
    background-color: #00aaaa;
    color: #111111;
    font-family: Proto;
    font-size: 14px;
    height: 45px;
    width: 325px;
    border-radius: 10px;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button_img2 {
    height: 15px;
    width: auto;
    margin-right: 10px;
}

.button2_disabled {
    background-color: #333333;
    color: #aaaaaa;
    font-family: Proto;
    font-size: 14px;
    height: 45px;
    width: 325px;
    border-radius: 10px;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    cursor:not-allowed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: line-through;
}