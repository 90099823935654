.container {
    font-family: Proto;
    background: #1e1e1e;
    color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: auto;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin-top: 50px;
    margin-bottom: 50px;
}

.header h1 {
    font-size: 24px;
    color: #FFFFFF;
    margin: 0;
}

.header h2 {
    font-size: 16px;
    color: #bbbbbb;
    margin-bottom: 20px;
}

.statusCard {
    background: #2a2a2a;
    border-radius: 10px;
    padding: 20px;
    text-align: left;
}

.serverInfo {
    display: flex;
    align-items: center;
    gap: 20px;
}

.serverImage {
    width: 64px;
    height: 64px;
    border-radius: 10px;
}

.serverDetails p {
    margin: 5px 0;
}

.motd {
    font-size: 14px;
    font-weight: bold;
}

.status {
    font-size: 14px;
}

.online {
    color: #00aa00;
}

.offline {
    color: #aa0000;
}

.version {
    font-size: 12px;
    color: #aaaaaa;
}

.playerCount {
    margin-top: 10px;
}

.progressBar {
    background: #444;
    border-radius: 5px;
    height: 10px;
    overflow: hidden;
    margin-top: 5px;
    width: 100%;
}

.progress {
    height: 100%;
    background: #00aaaa;
}

.playerList {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.playerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    background: #333;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s;
    width: 185px;
}

.playerContainer:hover {
    transform: scale(1.05);
}

.playerIcon {
    width: 32px;
    height: 32px;
    /* border-radius: 50%; */
}

@media screen and (max-width: 768px) {
    .container {
        width: 80%;
    }
}